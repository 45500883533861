<template>
    <setting-layout :title="$t('pages.user.setting.creator.tip_menu.title')">
        <v-container>
            <v-row>
                <v-card-text>
                    <li>
                        {{$t('pages.user.setting.creator.tip_menu.sub_title_1')}}
                    </li>
                    <li>
                        {{$t('pages.user.setting.creator.tip_menu.sub_title_2')}}   
                    </li>
                    <li>
                        {{ $t('pages.user.setting.creator.tip_menu.zero_price') }}
                    </li>
                </v-card-text>
            </v-row>
            <v-row v-for="(item, index) in model" :key="index" class="align-center">
                <v-col cols="6">
                    <text-field
                        v-model="item.name"
                        :placeholder="$t('pages.user.setting.creator.tip_menu.enter_name')"
                        :error-messages="'tips.' + index + '.name' in errors ? errors['tips.' + index + '.name'] : []"
                        outlined
                    />
                </v-col>
                <v-col cols="5">
                    <div v-if="item.price == 0 && !item.isEditing">
                        <text-field
                            :error-messages="errors.price"
                            placeholder="0-100"
                            v-on:keyup.enter="save"
                            v-on:focus="item.isEditing = true"
                            v-model="valueTipsZeroPrice"
                            outlined
                        />
                    </div>
                    <text-field
                        v-else
                        :error-messages="errors.price"
                        placeholder="0-100"
                        :prepend="currency"
                        v-on:keyup.enter="save"
                        v-on:focus="item.isEditing = true"
                        v-model="item.price"
                        outlined
                    />
                </v-col>
                <v-col cols="1" class="px-2">
                    <span @click="deleteTip(item.id, index)">
                        <app-icon icon="delete" size="28" />
                    </span>
                </v-col>
            </v-row>
            <v-row justify="center" v-if="this.model.length < 10">
                <v-btn
                    fab
                    color="white"
                    height="50"
                    width="50"
                    ripple
                    @click="addField"
                    class="mt-6"
                >
                    <div class="add">
                        <svg class="icon_plus" xmlns="http://www.w3.org/2000/svg" width="20.67" height="20.671"
                             viewBox="0 0 20.67 20.671">
                            <g id="Сгруппировать_22651" data-name="Сгруппировать 22651" transform="translate(1 1)">
                                <path id="Контур_827" data-name="Контур 827" d="M10603,637h18.67"
                                      transform="translate(-10603 -627.664)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="2"/>
                                <path id="Контур_828" data-name="Контур 828" d="M0,0H18.671"
                                      transform="translate(9.336) rotate(90)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </svg>
                    </div>
                </v-btn>
            </v-row>
            <v-row justify="center" class="mt-8">
                <gradient-button block @click="save" :loading="$store.state.buttonsLoading.tipBtn">
                    {{ $t('pages.user.setting.creator.tip_menu.save') }}
                </gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {CURRENCY_SYMBOL} from "@/configs/constants";

import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "TipsSetting",
    data: () => ({
        errors: {},
        model: [],
        currency: CURRENCY_SYMBOL,
    }),
    computed: {
        valueTipsZeroPrice() {
            return this.$t('pages.user.setting.creator.tip_menu.on_request')
        },
    },
    methods: {
        ...mapActions([
            'sendTipsMenu',
            'getTipsMenu',
            'deleteTipsMenu',
        ]),
        ...mapMutations(['showSnackBar']),

        addField() {
            this.model.push({name: '', price: '', isEditing: true})
        },
        clearEmptyField() {
            this.model = this.model.filter((item) => !(item.name === '' && item.price === ''))
        },
        save() {
            this.clearEmptyField()
            this.sendTipsMenu({
                model: {
                    tips: this.model
                }
            })
                .then((res) => {
                    this.errors = {}
                    this.showSnackBar(this.$t('pages.user.setting.creator.tip_menu.saved'))
                    if(res.data) {
                        this.model = res.data.map(item => {
                            return {
                                ...item,
                                isEditing: false // Initialize isEditing as false
                            };
                        });
                    }
                })
                .catch(err => this.errors = err.response.data.errors)
        },
        deleteTip(id, index){
            if (id) {
                this.deleteTipsMenu(id).then(() => {
                    this.model = this.model.filter(item => item.id !== id);
                });
            } else {
                this.model.splice(index, 1);
            }
        },
    },
    mounted() {
        this.getTipsMenu(this.$store.state.user.user.data.id)
            .then((res) => {
                this.model = res.data.map(item => {
                    return {
                        ...item,
                        isEditing: false // Initialize isEditing as false
                    };
                });
            })
    },
    components: {GradientButton, TextField, SettingLayout}
}
</script>

<style scoped>
.add {
    background: black;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    font-size: 2.95em;
    color: white
}

.icon_plus {
    margin-bottom: 3px;
}
</style>
